import React from 'react';

import styled, { css } from 'styled-components';

import {
  HeaderNavConfig,
  NavigationItemProps,
} from '../../../config/headerNavigation';

import BurgerModal from './BurgerModal';
import { Navigation } from './header-components/Navigation/Navigation';
import { headerThemes } from './themes';
import { ImageWrapper } from '@dist-property-frontend/toolkit';

const ThemeProvider = require('styled-components').ThemeProvider;

type HeaderProps = {
  isUserLoggedIn: boolean;
  asPath: string;
  standardNavConfig?: NavigationItemProps[];
  mobileStandardNavConfig?: NavigationItemProps[];
  navigation?: HeaderNavConfig;
  ctaNavConfig?: NavigationItemProps;
  LinkComponent: any;
  themeName?: 'COMMERCIAL' | 'ALTERNATIVE' | 'RESIDENTIAL' | 'AGENT_TOOLS';
  logoConfig?: {
    url: string;
    href: string;
    alt: string;
  };
  hasFixedPosition?: boolean;
  disableLogo?: boolean;
};

const BaseNavWrapperFlexLayout = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    justify-content: flex-start;
  }
`;

const HeaderWrapper = styled.header<{ hasFixedPosition: boolean | undefined }>`
  ${BaseNavWrapperFlexLayout}
  width: 100%;
  height: auto;
  position: ${({ hasFixedPosition }) =>
    hasFixedPosition ? 'fixed' : 'relative'};
  z-index: 600;
  background-color: ${({ theme }) => theme.headerTheme?.headerBackgroundColor};
  max-height: 56px;
  @media only screen and (min-width: 900px) {
    position: relative;
    max-height: 70px;
  }
`;

const HeaderContent = styled.div`
  width: 100%;
  height: auto;

  padding: ${({ theme }) => theme.spacing.M16};
  @media only screen and (min-width: 720px) {
    padding: 23px 16px;
  }
  @media only screen and (min-width: 1040px) {
    padding: 23px 0;
  }
  max-width: 1040px;
  margin: 0 auto;
  color: ${({ theme }) => theme.headerTheme?.navLinkColor};
  ${({ theme }) =>
    theme.headerTheme?.name === 'ALTERNATIVE' &&
    ` 
      @media only screen and (min-width: ${theme.headerTheme?.pageBreakpoint}) {
        border-bottom: 0;
      }
      *:focus {
        outline: 1px dotted rgba(0, 0, 0, 0.5); 
      }
    `};

  ${BaseNavWrapperFlexLayout}
`;

const NavWrapperLayoutCSS = css`
  ${BaseNavWrapperFlexLayout}
  flex-grow: 1;
`;

const MobileNavWrapper = styled.div`
  ${NavWrapperLayoutCSS}
  justify-content: flex-end;
  > ul:first-of-type a {
    margin-left: 0;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    display: none;
  }
`;

const DesktopNavWrapper = styled.div`
  ${NavWrapperLayoutCSS}
  display: none;
  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    display: flex;
    align-content: flex-end;
    > :last-child {
      margin-left: 0px;
    }
    > :nth-child(2) {
      margin-left: auto;
    }
  }
`;

const LogoWrapper = styled.div`
  height: 20px;
  width: 72px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    height: 25px;
    width: 90px;
  }
  display: block;
  position: relative;
`;

const LogoLink = styled.a<{ cursorAuto?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #3a64ae;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.REGULAR};
  ${({ cursorAuto }) => cursorAuto && 'cursor: auto;'}
  @media only screen and (min-width: ${({ theme }) =>
    theme.headerTheme?.pageBreakpoint}) {
    margin-right: 56px;
  }
`;

const NavHolder = styled.div`
  margin-right: 14px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    :last-of-type {
      margin-right: 0;
    }
  }
`;

export const Header = ({
  isUserLoggedIn,
  standardNavConfig,
  mobileStandardNavConfig,
  navigation,
  ctaNavConfig,
  themeName,
  logoConfig,
  asPath,
  LinkComponent,
  hasFixedPosition,
  disableLogo,
}: HeaderProps) => {
  const headerTheme = themeName
    ? headerThemes.get(themeName)
    : headerThemes.get('RESIDENTIAL');

  const renderNav = ({
    navigationItems,
    rightAlign,
  }: {
    navigationItems?: NavigationItemProps | NavigationItemProps[];
    rightAlign?: boolean;
  }) => (
    <NavHolder>
      <Navigation
        navigationItems={
          navigationItems &&
          (Array.isArray(navigationItems) ? navigationItems : [navigationItems])
        }
        rightAlign={rightAlign}
        LinkComponent={LinkComponent}
        asPath={asPath}
      />
    </NavHolder>
  );

  const accountNavConfig = isUserLoggedIn
    ? navigation?.authenticatedAccountNavConfig
    : navigation?.unAuthenticatedAccountNavConfig
      ? navigation.unAuthenticatedAccountNavConfig
      : undefined;

  const mobileAccountNavConfig = isUserLoggedIn
    ? navigation?.authenticatedAccountNavConfig
    : navigation?.mobileUnAuthenticatedAccountNavConfig
      ? navigation.mobileUnAuthenticatedAccountNavConfig
      : undefined;

  const placeAdNavConfig = navigation?.placeAdConfig
    ? navigation?.placeAdConfig
    : [];

  return (
    <ThemeProvider theme={{ headerTheme }}>
      <HeaderWrapper
        data-testid="header-wrapper"
        hasFixedPosition={hasFixedPosition}
      >
        <HeaderContent>
          <LogoLink
            href={logoConfig?.href}
            data-testid="logo-link"
            onClick={(e) => disableLogo && e.preventDefault()}
            cursorAuto={disableLogo}
          >
            <LogoWrapper>
              <ImageWrapper
                alt={logoConfig?.alt}
                src={logoConfig?.url}
                sizes="100vw"
                width={0}
                height={0}
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: 'auto',
                }}
                unoptimized
              />
            </LogoWrapper>
          </LogoLink>
          <MobileNavWrapper data-testid="mobile-nav-wrapper">
            {ctaNavConfig &&
              renderNav({
                navigationItems: [ctaNavConfig],
                rightAlign: true,
              })}
            {standardNavConfig || mobileAccountNavConfig ? (
              <BurgerModal isOpen={false} data-testid="burger-modal">
                <ThemeProvider
                  theme={{ headerTheme: headerThemes.get('MODAL') }}
                >
                  {renderNav({
                    navigationItems: mobileAccountNavConfig,
                  })}
                  {renderNav({
                    navigationItems: mobileStandardNavConfig,
                  })}
                  {renderNav({
                    navigationItems: placeAdNavConfig,
                  })}
                </ThemeProvider>
              </BurgerModal>
            ) : null}
          </MobileNavWrapper>

          <DesktopNavWrapper data-testid="desktop-nav-wrapper">
            {renderNav({
              navigationItems: standardNavConfig,
            })}
            {renderNav({
              navigationItems: placeAdNavConfig,
              rightAlign: true,
            })}
            {renderNav({
              navigationItems: accountNavConfig,
              rightAlign: true,
            })}
          </DesktopNavWrapper>
        </HeaderContent>
      </HeaderWrapper>
    </ThemeProvider>
  );
};
