import {
  GalleryImageObjectFit,
  type GalleryImageProps,
  type GalleryProps,
} from './Gallery.typed';
import Empty from '../../../../assets/empty.svg';
import { RoundButton, Spinner } from '../../atoms';
import * as Styled from './Gallery.styled';
import { RoundButtonTypes } from '../../atoms/roundButton/RoundButton.types';
import { useGallery } from './Gallery.hooks';
import ImageGrid from '../../atoms/imageGrid/ImageGrid';
import GalleryIndicator from '../../atoms/galleryIndicator/GalleryIndicator';
import { Fragment, useEffect, useState } from 'react';

export const Gallery = (props: GalleryProps) => {
  const {
    aspectRatio,
    aspectRatioMobile,
    children,
    initialIndex,
    isLoading = false,
    loadingSpinner = <Spinner />,
    objectFit,
    onChange = () => {},
    hasSlideAnimation = false,
    bottomLeftComponent,
    bottomRightComponent,
    topLeftComponent,
    topRightComponent,
    imageGridAspectRatioHorizontal,
    imageGridImages = [],
    visibleGridImagesCount = 0,
    hasIndicator = false,
    dataTracking,
    forceMobileView,
    fallbackImage,
    ...rest
  } = props;
  const [index, setIndex] = useState(initialIndex || 0);
  const [previousIndex, setPreviousIndex] = useState(initialIndex || 0);
  const {
    handleTouchStart,
    handleTouchEnd,
    handleMouseDown,
    handleMouseUp,
    loadedGalleryImages,
    goToNext,
    goToPrev,
    items,
  } = useGallery(setIndex, setPreviousIndex, children, index, onChange);

  const { hoverInteraction = false, imageLoading = 'lazy' } = props;

  const [skipAnimation, setSkipAnimation] = useState(false);
  const [translateXOffset, setTranslateXOffset] = useState(0);
  useEffect(() => {
    // Calculate the offset for sliding effect. Total width of each image is 100% so
    // We just need to slide 100% times whichever index we are at
    const SLIDE_WIDTH = 100;
    const transformMultiplier = index;
    if (
      (previousIndex === items.length - 1 && index === 0) ||
      (index === items.length - 1 && previousIndex === 0)
    ) {
      setSkipAnimation(true);
    } else {
      setSkipAnimation(false);
    }

    setTranslateXOffset(-transformMultiplier * SLIDE_WIDTH);
  }, [index, items, previousIndex]);

  useEffect(() => {
    setIndex(initialIndex || index);
  }, [initialIndex]);

  const GalleryImageContent = (): JSX.Element[] => {
    // Handle loading first with early return
    if (isLoading) {
      return [
        <Styled.GalleryLoadingSpinnerWrapper>
          {loadingSpinner}
        </Styled.GalleryLoadingSpinnerWrapper>,
      ];
    }

    // Handle no images with early return
    if (items.length === 0) {
      return [
        <Gallery.Image
          key="NoImage"
          src={fallbackImage || (Empty as unknown as string)}
          alt="NoImage"
          loading={imageLoading}
        />,
      ];
    }

    // If we have the images and are not loading, display them
    const imageGalleryItems = items.map((item, itemIndex) =>
      loadedGalleryImages.includes(itemIndex) ? (
        <Fragment key={itemIndex}>{item}</Fragment>
      ) : (
        <Styled.UnloadedGalleryImage
          key={itemIndex}
        ></Styled.UnloadedGalleryImage>
      ),
    );
    return imageGalleryItems;
  };

  return (
    <Styled.Gallery
      {...rest}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      visibleGridImagesCount={visibleGridImagesCount}
      forceMobileView={forceMobileView}
      data-testid="gallery"
      {...(dataTracking && {
        'data-tracking': dataTracking?.['gallery'],
      })}
    >
      <Styled.GalleryImageContainer
        aspectRatio={aspectRatio}
        aspectRatioMobile={aspectRatioMobile}
        {...(dataTracking && {
          'data-tracking': dataTracking?.['galleryImageContainer'],
        })}
      >
        <Styled.Item
          objectFit={objectFit ?? GalleryImageObjectFit.COVER}
          translateXOffset={translateXOffset}
          hasSlideAnimation={hasSlideAnimation && !skipAnimation}
        >
          {GalleryImageContent()}
        </Styled.Item>

        {topLeftComponent && (
          <Styled.Slot slotPosition="top-left">{topLeftComponent}</Styled.Slot>
        )}
        {topRightComponent && (
          <Styled.Slot slotPosition="top-right">
            {topRightComponent}
          </Styled.Slot>
        )}
        {items.length > 0 && hasIndicator && (
          <Styled.GalleryImageIndicators>
            <GalleryIndicator currentIndex={index} items={items.length} />
          </Styled.GalleryImageIndicators>
        )}
        {bottomLeftComponent && (
          <Styled.Slot
            slotPosition="bottom-left"
            {...(dataTracking && {
              'data-tracking': dataTracking?.['bottomLeftComponent'],
            })}
          >
            {bottomLeftComponent}
          </Styled.Slot>
        )}
        {bottomRightComponent && (
          <Styled.Slot
            slotPosition="bottom-right"
            {...(dataTracking && {
              'data-tracking': dataTracking?.['bottomRightComponent'],
            })}
          >
            {bottomRightComponent}
          </Styled.Slot>
        )}
        {items.length > 1 && (
          <Styled.Controls displayOnHover={hoverInteraction}>
            <RoundButton
              iconType="CHEVRONLEFT"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                goToPrev(e);
              }}
              aria-label="Previous"
              buttonType={RoundButtonTypes.OVERLAY}
              {...(dataTracking && {
                'data-tracking': dataTracking?.['galleryButtonPrevious'],
              })}
            >
              prev
            </RoundButton>
            <RoundButton
              iconType="CHEVRONRIGHT"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                goToNext(e);
              }}
              aria-label="Next"
              buttonType={RoundButtonTypes.OVERLAY}
              {...(dataTracking && {
                'data-tracking': dataTracking?.['galleryButtonNext'],
              })}
            >
              Next
            </RoundButton>
          </Styled.Controls>
        )}
      </Styled.GalleryImageContainer>
      {visibleGridImagesCount > 0 && (
        <ImageGrid
          dataTracking={dataTracking?.['imageGrid']}
          firstVisibleThumbnailIndex={
            index + 1 >= imageGridImages.length ? 0 : index + 1
          }
          aspectRatioVertical={aspectRatio}
          aspectRatioHorizontal={imageGridAspectRatioHorizontal}
          imageGridImages={imageGridImages}
          imageLoading={imageLoading}
          skipImageIndex={index}
          visibleGridImagesCount={visibleGridImagesCount}
          forceMobileView={forceMobileView}
          fallBackImage={fallbackImage}
        />
      )}
    </Styled.Gallery>
  );
};

// Future proofing in case we need more control
Gallery.Image = (props: GalleryImageProps) => {
  return (
    <img
      {...props}
      alt={props.alt}
      src={props.src}
      draggable={false}
      loading={props.loading}
    />
  );
};

export default Gallery;
