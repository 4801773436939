import { useEffect, useRef, useState } from 'react';
import { ContentElementTypes, QuickFilterProps } from './QuickFilter.types';
import {
  QuickFilterBottomSheetWrapper,
  QuickFilterMenuWrapper,
  QuickFilterWrapper,
  TriggerElementContainer,
} from './QuickFilter.styled';
import { Button, ButtonSizes, ButtonTypes, Icon } from '../../atoms';
import BottomSheet from '../../molecules/bottomSheet/BottomSheet';
import Menu from '../../molecules/menu/Menu';

export const QuickFilter = ({
  closeFilter,
  contentElement,
  desktopContentElementType = ContentElementTypes.MENU,
  filterToggleText,
  mobileContentElementType = ContentElementTypes.BOTTOM_SHEET,
  isOpen,
  showStickyFooterIn = [],
  showStickyHeaderIn = [],
  stickyFooter,
  stickyHeader,
  toggleFilter,
}: QuickFilterProps) => {
  const [alignment, setAlignment] = useState<'LEFT' | 'RIGHT'>('LEFT');
  const triggerElementRef = useRef<HTMLDivElement>(null);
  const bottomSheetContentElementRef = useRef<HTMLDivElement>(null);
  const menuElementRef = useRef<HTMLDivElement>(null);
  const [menuElementTop, setMenuElementTop] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        triggerElementRef.current &&
        bottomSheetContentElementRef.current &&
        menuElementRef.current &&
        !triggerElementRef.current.contains(event.target as Node) &&
        !bottomSheetContentElementRef.current.contains(event.target as Node) &&
        !menuElementRef.current.contains(event.target as Node)
      ) {
        closeFilter();
      }
    };

    setMenuElementTop(
      menuElementRef?.current?.getBoundingClientRect().top || 0,
    );

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen, closeFilter]);

  const toggleDropdown = () => {
    if (!isOpen && triggerElementRef.current) {
      const triggerRect = triggerElementRef.current.getBoundingClientRect();
      const screenWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
      const triggerCenter = triggerRect.left + triggerRect.width / 2;

      setAlignment(triggerCenter < screenWidth / 2 ? 'LEFT' : 'RIGHT');
    }
    toggleFilter();
  };

  /*
   * Typically we will render the menuModal in desktop and bottomSheet in mobile
   * But there may be cases in the future where we want to render the menuModal in mobile or bottomSheet in desktop
   * To handle this we allow specifying the contentElementType for both desktop and mobile
   * We display none on the elements we don't want to show until the media query matches the prop condition
   */
  return (
    <QuickFilterWrapper>
      <TriggerElementContainer
        onClick={toggleDropdown}
        ref={triggerElementRef}
        id={filterToggleText}
      >
        <Button
          buttonType={ButtonTypes.TERTIARY}
          buttonSize={ButtonSizes.MEDIUM}
          Icon={<Icon icon="CHEVRONDOWN" size={16} />}
          position="right"
          active={isOpen}
        >
          {filterToggleText}
        </Button>
      </TriggerElementContainer>

      {(desktopContentElementType === ContentElementTypes.BOTTOM_SHEET ||
        mobileContentElementType === ContentElementTypes.BOTTOM_SHEET) && (
        <QuickFilterBottomSheetWrapper
          desktopContentElementType={desktopContentElementType}
          isOpen={isOpen}
          mobileContentElementType={mobileContentElementType}
        >
          <BottomSheet
            contentElement={contentElement}
            contentElementRef={bottomSheetContentElementRef}
            stickyFooter={
              showStickyFooterIn.includes(ContentElementTypes.BOTTOM_SHEET)
                ? stickyFooter
                : undefined
            }
            stickyHeader={
              showStickyHeaderIn.includes(ContentElementTypes.BOTTOM_SHEET)
                ? stickyHeader
                : undefined
            }
          />
        </QuickFilterBottomSheetWrapper>
      )}

      {(desktopContentElementType === ContentElementTypes.MENU ||
        mobileContentElementType === ContentElementTypes.MENU) && (
        <QuickFilterMenuWrapper
          desktopContentElementType={desktopContentElementType}
          isOpen={isOpen}
          mobileContentElementType={mobileContentElementType}
          ref={menuElementRef}
        >
          <Menu
            alignment={alignment}
            contentElement={contentElement}
            parentElementTop={menuElementTop}
            stickyFooter={
              showStickyFooterIn.includes(ContentElementTypes.MENU)
                ? stickyFooter
                : undefined
            }
            stickyHeader={
              showStickyHeaderIn.includes(ContentElementTypes.MENU)
                ? stickyHeader
                : undefined
            }
          />
        </QuickFilterMenuWrapper>
      )}
    </QuickFilterWrapper>
  );
};
