import { useEffect, useState } from "react";

/**
 * Check if the current device is a mobile device
 * @param breakpoint 
 * @returns isMobileState - A boolean indicating if the current device is a mobile device
 */
export const useCheckIsMobile = (breakpoint = 768) => {
  const [isMobileState, setIsMobileState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleResize = () => {
      setIsMobileState(window.innerWidth <= breakpoint);
    };

    // Check the initial window size on component mount
    handleResize();
    setIsLoading(false);

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return { isMobileState, isLoading };
};

/**
 * Check if a feature flag is enabled
 * @param featureFlag - The feature flag to check
 * @returns newFeatureFlag - A boolean indicating if the feature flag is enabled
*/ 
export const useFeatureFlag = (featureFlag: string) => {
  const [newFeatureFlag, setNewFeatureFlag] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isFeatureActive = localStorage?.getItem(featureFlag) === 'true';
      setNewFeatureFlag(isFeatureActive);
    }
  }, []);

  return newFeatureFlag;
};