import styled from 'styled-components';
import { ContentElementTypes } from './QuickFilter.types';

export const QuickFilterWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const TriggerElementContainer = styled.div``;

export const QuickFilterBottomSheetWrapper = styled.div<{
  desktopContentElementType: ContentElementTypes;
  isOpen: boolean;
  mobileContentElementType: ContentElementTypes;
}>`
  display: ${({ mobileContentElementType, isOpen }) =>
    mobileContentElementType === ContentElementTypes.BOTTOM_SHEET && isOpen
      ? 'block'
      : 'none'};

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.large}) {
    display: ${({ desktopContentElementType, isOpen }) =>
      desktopContentElementType === ContentElementTypes.BOTTOM_SHEET && isOpen
        ? 'block'
        : 'none'};
  }
`;

export const QuickFilterMenuWrapper = styled.div<{
  desktopContentElementType: ContentElementTypes;
  isOpen: boolean;
  mobileContentElementType: ContentElementTypes;
}>`
  display: ${({ mobileContentElementType, isOpen }) =>
    mobileContentElementType === ContentElementTypes.MENU && isOpen
      ? 'block'
      : 'none'};

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.large}) {
    display: ${({ desktopContentElementType, isOpen }) =>
      desktopContentElementType === ContentElementTypes.MENU && isOpen
        ? 'block'
        : 'none'};
  }
`;
