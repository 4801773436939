import React, { useState } from 'react';
import { KeywordSearchProps } from './KeywordSearch.types';
import { Input } from '../../atoms';
import FormControl from '../formControl/FormControl';
import { KeywordSearchForm } from './KeywordSearch.styled';

export const KeywordSearch = (props: KeywordSearchProps) => {
  const {
    disabled = false,
    errorText = '',
    leftSlot,
    maxLength,
    minLength,
    onChange,
    onClear,
    onSubmit,
    placeholder = 'Search...',
    rightSlots = [],
    value,
    ...rest
  } = props;
  const [localErrorText, setLocalErrorText] = useState('');

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (minLength && value.length < minLength) {
      setLocalErrorText(`Please enter at least ${minLength} characters`);
      return;
    }

    return onSubmit(value);
  };

  const handleOnChange = (value: string) => {
    if (localErrorText) {
      setLocalErrorText('');
    }
    return onChange(value);
  };

  // Local error takes precedence over global error
  const errorTextToDisplay = localErrorText || errorText;

  return (
    <KeywordSearchForm {...rest} onSubmit={handleOnSubmit}>
      <FormControl
        disabled={disabled}
        notificationMessage={errorTextToDisplay}
        showNotification={Boolean(errorTextToDisplay)}
      >
        <Input
          enterKeyHint="search"
          leading={leftSlot}
          maxLength={maxLength}
          onChange={(event) => handleOnChange(event.target.value)}
          onClear={onClear}
          placeholder={placeholder}
          role="search"
          trailing={rightSlots}
          type="text"
          value={value}
        />
      </FormControl>
    </KeywordSearchForm>
  );
};

export default KeywordSearch;
