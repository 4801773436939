
import { TInfoItem } from './InfoItem.types'
import { InfoItemStyle } from './InfoItem.styled'


export const InfoItem = ({children, spacing, align, columnGap} : TInfoItem) => {
    return (
        <InfoItemStyle spacing={spacing} align={align} columnGap={columnGap}> 
            {children}
        </InfoItemStyle>
    )
}