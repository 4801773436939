import React from 'react';
import { Select } from '../../atoms';
import { RangeSelectProps } from './RangeSelect.types';
import { RangeSelectWrapper, SelectContainer } from './RangeSelect.styled';

export const RangeSelect = ({
  disabled = false,
  hasError = false,
  maxLabel = 'Max',
  maxValue = '',
  minLabel = 'Min',
  minValue = '',
  onChange,
  options = [],
}: RangeSelectProps) => {
  /*
   * We slice the options to ensure we only allow selecting min values smaller or equal to the max and vice versa
   * If the props sent in have max smaller than min or vice versa, we default to the full list of options and allow the
   * user to select any value to fix the filter
   */
  const maxValueIndex = options.indexOf(maxValue);
  const minValueIndex = options.indexOf(minValue);
  const isMaxValueValid =
    maxValueIndex !== -1 &&
    (minValueIndex === -1 || maxValueIndex >= minValueIndex);
  const isMinValueValid =
    minValueIndex !== -1 &&
    (maxValueIndex === -1 || minValueIndex <= maxValueIndex);

  const minSliceEnd = (() => {
    if (maxValue === '') return options.length; // Case 1: Max value is not set, show all results
    if (isMaxValueValid) return maxValueIndex + 1; // Case 2: Valid maxValue, splice to that index
    return options.length; // Case 3: Invalid maxValue, show all results in the min select
  })();

  const maxSliceStart = (() => {
    if (minValue === '') return 0; // Case 1: Min value is not set, show all results
    if (isMinValueValid) return minValueIndex; // Case 2: Valid minValue, splice from that index
    return 0; // Case 3: Invalid minValue, show all results
  })();

  const minOptions = [
    <Select.Option key="" value="">
      {minLabel}
    </Select.Option>,
    ...options.slice(0, minSliceEnd).map((option) => (
      <Select.Option key={option} value={option}>
        {option}
      </Select.Option>
    )),
  ];

  const maxOptions = [
    <Select.Option key="" value="">
      {maxLabel}
    </Select.Option>,
    ...options.slice(maxSliceStart).map((option) => (
      <Select.Option key={option} value={option}>
        {option}
      </Select.Option>
    )),
  ];

  return (
    <RangeSelectWrapper>
      <SelectContainer>
        <Select
          aria-label={minLabel}
          disabled={disabled}
          hasError={hasError}
          onChange={(e) => onChange({ min: e.target.value, max: maxValue })}
          value={minValue}
        >
          {minOptions}
        </Select>
      </SelectContainer>

      <SelectContainer>
        <Select
          aria-label={maxLabel}
          disabled={disabled}
          hasError={hasError}
          onChange={(e) => onChange({ min: minValue, max: e.target.value })}
          value={maxValue}
        >
          {maxOptions}
        </Select>
      </SelectContainer>
    </RangeSelectWrapper>
  );
};

export default RangeSelect;
