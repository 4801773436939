import { SyntheticEvent, useState } from 'react';
import { getContrastYIQ } from '../../../../../helpers/ContrastColorChecker';
import { ImageWrapper, MaskedContainer, MaskedContainerSize } from '../../../atoms';
import { BrandBanner, BrandBannerSize } from '../../../organisms';
import {
  ResponsiveLogo,
  ResponsiveSubtitle,
  ResponsiveTitle,
} from './CustomCardBanner.styled';
import { useFeatureFlag } from '../../../../../helpers/utils';

type CustomCardBannerType = {
  props: CardBannerUIType;
};
export type CardBannerUIType = {
  rightMaskedImage: string;
  rightMaskedImageAlt: string;
  rightTitle: string;
  rightSubtitle: string;
  leftSlot: string;
  fallbackText: string;
  backgroundColor: string;
  size: BrandBannerSize;
  dataTracking?: { [key: string]: string };
  maskedContainerSize: {
    size: MaskedContainerSize;
    mobileSize: MaskedContainerSize;
    outline: boolean;
  };
};

export const CustomCardBanner = (
  bannerProps: CustomCardBannerType,
): JSX.Element => {
  const fontColor = bannerProps.props?.backgroundColor
    ? getContrastYIQ(bannerProps.props.backgroundColor)
    : '#333333';
  const dataTracking = bannerProps?.props?.dataTracking;

  // this is used to get the image dimensions for the leftSlot image to set the correct width and height for next/image
  const [imageDimensions, setImageDimensions] = useState<{ [key: string]: { width: number; height: number } }>({});
  const handleImageLoad = (id: string, event: SyntheticEvent<HTMLImageElement, Event>) => {
    const { naturalWidth, naturalHeight } = event.target as HTMLImageElement;
    setImageDimensions((prevDimensions) => ({
      ...prevDimensions,
      [id]: { width: naturalWidth, height: naturalHeight },
    }));
  };

  // this is used to check if the feature flag is enabled
  const nextImageFeatureCheck = useFeatureFlag('nextImageFeature');

  // this is used to render the leftSlot image with next/image if the feature flag is enabled
  const renderBannerImage = () => {
    if (!nextImageFeatureCheck) {
      return <img src={bannerProps.props?.leftSlot} alt="Logo" />;
    } else {
      return (
        <div style={{ position: 'relative' }}>
          <ImageWrapper 
            src={bannerProps.props?.leftSlot} 
            alt="Logo"
            onLoad={(event) => handleImageLoad('leftSlot', event)}
            width={imageDimensions['leftSlot']?.width || 200} // 200 is just a fallback value not an image size
            height={imageDimensions['leftSlot']?.height || 40}
            style={{ width: imageDimensions['leftSlot']?.width || 'auto', height: imageDimensions['leftSlot']?.height || 'auto' }} // this is needed to prevent the image from stretching on load then popping back to the correct size
          />
        </div>
      );
    }
  };

  return (
    <BrandBanner
      dataTracking={dataTracking}
      size={bannerProps.props?.size}
      backgroundColor={bannerProps?.props?.backgroundColor}
      fontColor={fontColor}
      rightSideImage={
        bannerProps.props?.rightMaskedImage ? (
          <MaskedContainer
            dataTracking={dataTracking?.['rightMaskedImage']}
            imageAlt={bannerProps.props?.rightMaskedImageAlt}
            image={bannerProps.props?.rightMaskedImage}
            outline={bannerProps.props?.maskedContainerSize?.outline}
            size={bannerProps.props?.maskedContainerSize?.size}
            mobileSize={bannerProps.props?.maskedContainerSize.mobileSize}
          />
        ) : undefined
      }
      rightTitle={
        <ResponsiveTitle
          {...(dataTracking && {
            'data-tracking': dataTracking?.['rightTitle'],
          })}
        >
          {bannerProps.props?.rightTitle}
        </ResponsiveTitle>
      }
      rightSubtitle={
        <ResponsiveSubtitle
          {...(dataTracking && {
            'data-tracking': dataTracking?.['rightSubtitle'],
          })}
        >
          {bannerProps.props?.rightSubtitle}
        </ResponsiveSubtitle>
      }
      leftSideLogo={
        bannerProps?.props?.leftSlot ? (
          renderBannerImage()
        ) : (
          <ResponsiveLogo>{bannerProps.props?.fallbackText}</ResponsiveLogo>
        )
      }
    />
  );
};
