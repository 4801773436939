import React, { useEffect, useState } from 'react';
import { ContentElementWrapper, MenuContainer } from './Menu.styled';
import { MenuProps } from './Menu.types';

export const Menu = ({
  alignment = 'LEFT',
  contentElement,
  parentElementTop,
  stickyFooter,
  stickyHeader,
}: MenuProps) => {
  const [maxHeight, setMaxHeight] = useState('640px');
  useEffect(() => {
    const availableHeight = window.innerHeight - parentElementTop;

    setMaxHeight(`${Math.min(640, availableHeight)}px`);
  }, [parentElementTop]);

  return (
    <MenuContainer alignment={alignment} maxHeight={maxHeight}>
      {stickyHeader}
      <ContentElementWrapper maxHeight={maxHeight}>
        {contentElement}
      </ContentElementWrapper>
      {stickyFooter}
    </MenuContainer>
  );
};

export default Menu;
