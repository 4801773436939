import { CloseIcon } from '@dsch/icons';

import { panelThemes } from './theme';
import {
  CloseContainer,
  Container,
  Image,
  MainImageWrapper,
  LinkWrapper,
  SecondImage,
  Subtitle,
  Title,
  TitleIconWrapper,
  ImageContainer,
  SecondImageContainer,
} from './Panel.styled';
import { PanelProps } from './Panel.types';
import { Button, ImageWrapper } from '@dist-property-frontend/toolkit';
import { useFeatureFlag } from '../../../helpers/utils';

const ThemeProvider = require('styled-components').ThemeProvider;

export const Panel = ({
  image,
  secondImage,
  imgPosition = 'center',
  title,
  titleIcon,
  subtitle,
  background = false,
  border = false,
  close = false,
  cta,
  ctaText,
  ctaLink,
  themeName,
  customTheme,
  buttonSize,
  buttonType,
  onClick,
  id,
  borderRadius = false,
  opensInNewTab = false,
}: PanelProps) => {
  const panelTheme =
    customTheme ??
    panelThemes.get(themeName ?? 'DEFAULT_VERTICAL_STANDARD_X_SMALL');

  const nextImageFeatureCheck = useFeatureFlag('nextImageFeature');
  return (
    <ThemeProvider theme={{ panelTheme }}>
      <Container
        background={background}
        border={border}
        borderRadius={borderRadius}
        id={id ? `${id}-panel` : 'panel-div'}
        data-testid={id ? `${id}-panel` : 'panel-div'}
        data-tracking={id ? `${id}-panel` : 'panel-div'}
      >
        {close && (
          <CloseContainer data-testid="panel-close" onClick={onClick}>
            <CloseIcon />
          </CloseContainer>
        )}

        {image && (
          <MainImageWrapper imgPosition={imgPosition}>
            {!nextImageFeatureCheck ? (
              <Image src={image} alt="panel-img" />
            ) : (
              <ImageContainer>
                <ImageWrapper
                  src={image}
                  alt="panel-img-1"
                  fill
                  style={{ objectFit: 'cover' }}
                  unoptimized
                />
              </ImageContainer>
            )}
            {secondImage &&
              (nextImageFeatureCheck ? (
                <SecondImageContainer>
                  <ImageWrapper
                    src={secondImage}
                    alt="panel-img-2"
                    fill
                    style={{ objectFit: 'cover' }}
                  />
                </SecondImageContainer>
              ) : (
                <SecondImage src={secondImage} alt="panel-img-2" />
              ))}
          </MainImageWrapper>
        )}

        <div>
          {titleIcon ? (
            <TitleIconWrapper>
              <Title image={!!image}>{title}</Title>
              {titleIcon}
            </TitleIconWrapper>
          ) : (
            <Title image={!!image}>{title}</Title>
          )}
          <Subtitle>{subtitle}</Subtitle>
          {cta === 'BUTTON' ? (
            <Button
              buttonType={buttonType}
              buttonSize={buttonSize}
              fullWidth={true}
              element={'a'}
              href={ctaLink}
              data-testid={id ? `${id}-panel-cta` : 'panel-button-link'}
              data-tracking={id ? `${id}-panel-cta` : 'panel-button-link'}
              openInNewTab={opensInNewTab}
            >
              {ctaText}
            </Button>
          ) : (
            <LinkWrapper>
              <a
                href={ctaLink}
                data-tracking={id ? `${id}-panel-cta` : 'panel-button-link'}
                target={opensInNewTab ? '_blank' : '_self'}
                rel="noreferrer"
              >
                {ctaText}
              </a>
            </LinkWrapper>
          )}
        </div>
      </Container>
    </ThemeProvider>
  );
};
