import { StickyFooterProps } from '../../molecules/stickyFooter/StickyFooter.types';
import { StickyHeaderProps } from '../../molecules/stickyHeader/StickyHeader.types';

export enum ContentElementTypes {
  BOTTOM_SHEET = 'BOTTOM_SHEET',
  MENU = 'MENU',
}

export type QuickFilterProps = {
  closeFilter: () => void;
  contentElement: React.ReactNode;
  desktopContentElementType?: ContentElementTypes;
  filterToggleText: string;
  mobileContentElementType?: ContentElementTypes;
  isOpen: boolean;
  showStickyFooterIn?: ContentElementTypes[];
  showStickyHeaderIn?: ContentElementTypes[];
  stickyFooter?: React.ReactElement<StickyFooterProps>;
  stickyHeader?: React.ReactElement<StickyHeaderProps>;
  stickyHeaderHasCloseButton?: boolean;
  toggleFilter: () => void;
};
