import {
  Dispatch,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import * as Types from '../DateTimePicker.types';
import DateTimePicker from '../DefaultDateTimePicker/DateTimePicker';
import { RangeLabel } from '../DateTimePicker.styled';

export const DateTimePickerRange = forwardRef<
  Types.TDateTimePickerRangeRef,
  Types.TDateTimePickerRange
>((props: Types.TDateTimePickerRange, ref) => {
  const {
    disabled,
    endDate: endDateProp,
    endDatePlaceholderText,
    hasError,
    onBlur,
    onChange,
    options,
    startDate: startDateProp,
    startDatePlaceholderText,
  } = props;

  const startDateRef = useRef<HTMLInputElement | null>(null);
  const endDateRef = useRef<HTMLInputElement | null>(null);

  const [startDate, setStartDate]: [
    Date | undefined,
    Dispatch<Date | undefined>,
  ] = useState(startDateProp);
  const [endDate, setEndDate]: [Date | undefined, Dispatch<Date | undefined>] =
    useState(endDateProp);

  useEffect(() => {
    setStartDate(startDateProp);
  }, [startDateProp]);

  useEffect(() => {
    setEndDate(endDateProp);
  }, [endDateProp]);

  useImperativeHandle(ref, () => ({
    startDateRef: startDateRef.current,
    endDateRef: endDateRef.current,
  }));

  const onStartDateChange = (date: Types.TDateTimePickerOnChange) => {
    setStartDate(date);
    onChange &&
      onChange({
        startDate: date,
        endDate,
        rangeSide: 'from',
      });
  };

  const onEndDateChange = (date: Types.TDateTimePickerOnChange) => {
    setEndDate(date);
    onChange &&
      onChange({
        startDate,
        endDate: date,
        rangeSide: 'to',
      });
  };

  return (
    <>
      <DateTimePicker
        id="startDate"
        ref={startDateRef}
        placeholderText={startDatePlaceholderText}
        /**
         * Note here that we are able to pass down popperProps to set the strategy to fixed
         * This was done to help in the situation where we use the datetimepicker inside a modal such as our menu component to avoid overflow issues
         * Found in this stack overflow an example of it and how we can pass down popper props through the react datepicker
         * https://stackoverflow.com/questions/64254322/react-date-picker-is-being-hidden-behind-overflow-parent-popover-fixed-placemen
         */
        popperProps={{ strategy: 'fixed' }}
        selectsStart
        selectedDate={startDate || undefined}
        startDate={startDate || undefined}
        endDate={endDate || undefined}
        maxDate={endDate}
        options={options}
        disabled={disabled}
        hasError={hasError}
        onChange={onStartDateChange}
        onBlur={onBlur}
      />
      <RangeLabel>to</RangeLabel>
      <DateTimePicker
        id="endDate"
        ref={endDateRef}
        placeholderText={endDatePlaceholderText}
        popperProps={{ strategy: 'fixed' }}
        selectsEnd
        selectedDate={endDate || undefined}
        startDate={startDate || undefined}
        endDate={endDate || undefined}
        minDate={startDate}
        options={options}
        disabled={disabled}
        hasError={hasError}
        onChange={onEndDateChange}
        onBlur={onBlur}
      />
    </>
  );
});

export default DateTimePickerRange;
